import React from 'react';
import './EnglishPage.css';

const EnglishPage = () => {
  return (
    <div className="english-page">
      <h1>אנגלית</h1>
      <p>תוכן לדף אנגלית.</p>
    </div>
  );
};

export default EnglishPage;
