import React from 'react';
import './CitizenshipPage.css';

const CitizenshipPage = () => {
  return (
    <div className="citizenship-page">
      <h1>אזרחות</h1>
      <p>תוכן לדף אזרחות.</p>
    </div>
  );
};

export default CitizenshipPage;
