import React from 'react';

import './LinguisticEducationPage.css';

const LinguisticEducationPage = () => {
  return (
    <div className="emergency-learning">
      <div className="content">
        <h1>למידה בשעת חירום</h1>
        
        <p>    1. למידה משולבת דיגיטל בחירום
        </p>

        <p>    המשרד מרכז סביבות תוכן וסדנאות דיגיטליות המספקות מענה לזמן הפוגה ורגיעה. תכנים לחיזוק החוסן השייכות והמוגנות ומענה חינוכי ורגשי. התכנים בקישור זה יתעדכנו מעת לעת ועומדים לשימוש צוותי ההוראה והתלמידים. 
        מצבי חירום במוסדות חינוך - חרבות ברזל  </p>

        <p>    2. תכנים לתקופת המלחמה
        </p>

        <p>    תכנים לתקופת המלחמה: ישראל במלחמה, חרבות ברזל - מנהל חברה ונוער, פעילויות ומשחקיהפגה למלחמה (פסג"ה לוד)
תכנים לתקופת מלחמה לתלמידים עם מוגבלויות מורכבות- חינוך חברתי ערכי קהלתי
תכנים בשפה הערבית: תכנים לתקופת מלחמה בשפה הערבית + ג'ניאלי בערבית לתקופת המלחמה
סרטון מה עושים כששומעים רעשים לא נעימים?
שפ"ינט - תדריכים, סדנאות לצוות ושיעורים מוכנים גם לחינוך מיוחד של השירות הפסיכולוגי ייעוצי במערכת החינוך
תכנים להורים + פורטל הורים בזמן מלחמת חרבות ברזל, 
סרטונים ותכנים לילדים - הסבר על תקופת המלחמה
משחק מסלול - המאפשר לתלמידים להבין , להכיל ולפתור בעיות בזמן המלחמה
מאגר חומר מקצועי לעבודה טיפולית בחירום. של הָאֲגֻדָּה הַיִּשְׂרְאֵלִית לַטִּפּוּל קוֹגְנִיטִיבִי הִתְנַהֲגוּתִי CBT  </p>

        <p>   3. חומרים וכלים ללמידה מרחוק בתחומי ההדרכה שלנו:
        </p>

        <p>    50 רעיונות לפעילויות הפגתיות בלמידה מרחוק
דרכי הוראה ולמידה בתקופת המלחמה
חינוך לשוני
מתמטיקה - אתרים לתרגול
מדעים - כלים ואפליקציות ללמידת חקר, יחידות הוראה ללמידה מרחוק, מערכי שיעור ופעילויות - מאורגן בהתאם לשכבות הגיל, 
אנגלית - כלים טכנולוגיים בהוראת האנגלית
מעברים - מרחב וירטואלי להיכרות והתנסות בעולם העבודה. פה תוכלו להכיר מקומות עבודה שונים, להתאמן על 11 מיומנויות ביצוע, ולהתכונן לראיון עבודה
ל"ב 21 - סרטונים לפתיחת סדנאות ולשימוש עם תלמידים
חינוך מיני חברתי- סדרת אפליקציות IDO ללימוד ותרגול מיומנויות יומיום של התנהלות בקהילה, היגיינה והתלבשות. האפליקציות מיועדות לחינוך המיוחד ומותאמות לבוגרים
גיל הרך - פעילויות ללמידה מרחוק
טכנופדגוגיה - מאיה כהן יצרה עבורנו הסבר על איך נכנסים, לומדים ומשתמשים בכלי הGOOGLEMEET לשיעורים סינכרוניים
סיפור חברתי- אפליקציית הסיפור החברתי בה ניתן ליצור סיפורים חברתיים להבנת המתרחש ולהבעת רגשות  </p>
        
        <p>    4. סביבות ענן הפתוחות לכל תלמידי ישראל בחינם בשעת חירום:
        </p>

        <p> המקפצה - שיעורי תגבור סדנאות העשרה
ג'ינג'ים - שם משתמש: למדה בחרום סיסמה: 2023
בריינפופ - שם משתמש: בריינפופ23 סיסמה: ברינפופ23
קסם המוזיקה - כנסו בתוך האתר דרך הזדהות אחידה של משרד החינוך
simplesteps - אתר עם משחקים לילדים
אופק - למידה ופעילויות
ענק של ספרים - פלטפורמה מבוססת AI לכתיבה ויצירת ספרים דיגיטליים. כניסה באמצעות הזדהות אחידה
Matific - פעילויות במתמטיקה לחינוך ביתי
מותאם לי - סביבת למידה מותאמת המתמקדת בתהליך רכישת מיומנויות השפה בדרך מהנה ואינטראקטיבית. פרטי כניסה – מייל: Mutam@gmail.com סיסמה: MUTAMLI (באותיות גדולות).</p>
     
     <p> 5. חדשות לתלמידים
     </p>
     

     <p>  חדשות ישראל לילדים- קבוצת וואצאפ עם מידע שנערך כל יום ע"י פסיכולוגית ואשת חינוך כדי שיתאים לבני נוער. חדשות אקטואליות, מסבירות פנים, מוסיפות רוגע וביטחון
תמצית חדשות לנוער - קבוצת ווטסאפ לבני נוער שיש בה ריכוז מתומצת של החדשות בלי תמונות ובלי סרטונים. 
ערוץ היוטיוב של חדשות לילדים </p>

     <p>   6. מאגרי סרטים וספרים חינמיים בתקופת המלחמה
     </p>


     <p>  עיברית - ספרים דיגיטליים חינם לילדים ונוער
ארכיון הסרטים הישראלי - מייחלים לימים שקטים יותר במהרה ושולחים חיזוק לאזרחי ישראל. אם אתם זקוקים להפוגה משידורי החדשות והרשתות החברתיות - סרטים לצפייה בחינם באתר ארכיון הסרטים הישראלי. בין הסרטים: "בירושלים", "ג'ני וג'ני", "בר 51", "תורת המידות", "איפה לילבס ילדת הקרקס ומה קרה בהונולולו?", "אור מן ההפקר", "מלכה לב אדום", "הצד השלישי של המטבע", "מקודשת" ועוד
קולנוע לב - בתי קולנוע לב מזמינים אתכם לצפות בסרטי ילדים ומשפחה, גם מהבית חינם וללא עלות  
סרטוני כושר לילדים - הפוגת כושר ולעידוד הנפש והגוף. עוברים את זה ביחד. שלכם, אסף רון אקטיביX אימוני כושר חווייתיים לילדים.
אקדמי פלוס - אקדמי פלוס מחזקת את מורי ותלמידי ישראל בתקווה שנחזור לשיגרה בטוחה במהרה. כל האתר פתוח מהבית לכל התלמידים והמורים. מאות סרטים ומערכי שיעור מחכים רק לכם
BIGI - תוכניות טלוויזיה, סדרות וסרטים של ערוץ הילדים  </p>

      </div>
    </div>
  );
};

export default LinguisticEducationPage;
