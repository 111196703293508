import React from 'react';
import './SciencePage.css';

const SciencePage = () => {
  return (
    <div className="science-page">
      <h1>מדע וטכנולוגיה</h1>
      <p>תוכן לדף מדע וטכנולוגיה.</p>
    </div>
  );
};

export default SciencePage;
