import React from 'react';
import './ArtPage.css';

const ArtPage = () => {
  return (
    <div className="art-page">
      <h1>אמנות</h1>
      <p>תוכן לדף אמנות.</p>
    </div>
  );
};

export default ArtPage;
